<template>
    <!-- <div class="grid">
        <div class="col"> -->
    <div class="grid">
        <Toast />
        <ConfirmDialog></ConfirmDialog>
        <div class="col-12">
            <div class="card">
                <div class="grid p-fluid">
                    <div class="col-4">
                        <span class="p-float-label mt-4">
                            <InputText id="NIM" required="true" autofocus v-model.lazy="data.NIM_PTS" />
                            <label for="NIM">NIM</label>
                        </span>
                    </div>
                    <div class="col-4">
                        <span class="p-float-label mt-4">
                            <InputText id="nama_mahasiswa" required="true" autofocus v-model.lazy="data.nama_mahasiswa" />
                            <label for="nama_mahasiswa">Nama Mahasiswa</label>
                        </span>
                    </div>
                    <div class="col-4">
                        <span class="p-float-label mt-4">
                            <Dropdown dataEditKey="value" filter id="id" v-model.lazy="data.tahun_akademik_id" :options="tahun" optionLabel="text" optionValue="value" />
                            <label for="tahun_akademik_id">Tahun Akademik</label>
                        </span>
                    </div>
                </div>
                <div class="grid p-fluid">
                    <div class="col-4">
                        <span class="p-float-label mt-4">
                            <Dropdown dataEditKey="value" filter id="prodi_id" v-model.lazy="data.prodi_id" :options="prodi" optionLabel="text" optionValue="value" />
                            <label for="prodi_id">Prodi</label>
                        </span>
                    </div>
                    <div class="col-4">
                        <span class="p-float-label mt-4">
                            <Dropdown dataEditKey="value" filter id="jenis_beasiswa" v-model.lazy="data.jenis_beasiswa" :options="option_jenis_beasiswa" optionLabel="text" optionValue="value" />
                            <label for="jenis_beasiswa">Jenis Beasiswa</label>
                        </span>
                    </div>
                    <div class="col-4">
                        <span class="p-float-label mt-4">
                            <InputText id="total_tagihan" required="true" autofocus v-model.lazy="data.total_tagihan" />
                            <label for="total_tagihan">Total Tagihan</label>
                        </span>
                    </div>
                </div>
                <div class="grid p-fluid">
                    <div class="col-5">
                        <span class="p-float-label mt-4">
                            <Calendar :showIcon="true" :showButtonBar="true" dateFormat="yy-mm-dd" :disabled="!checked" v-model.lazy="data.bayar_mulai"></Calendar>
                            <label for="bayar_mulai">Tanggal Pembukaan</label>
                        </span>
                    </div>
                    <div class="col-5">
                        <span class="p-float-label mt-4">
                            <Calendar :showIcon="true" :showButtonBar="true" dateFormat="yy-mm-dd" :disabled="!checked" v-model.lazy="data.bayar_tutup"></Calendar>
                            <label for="bayar_tutup">Tanggal Penutupan</label>
                        </span>
                    </div>
                    <div class="col-1 mt-2">
                        <span class="p-float-label mt-4 text-right"><InputSwitch v-model="checked" /></span>
                    </div>
                    <div class="col-1 mt-2">
                        <span class="p-float-label mt-4 text-left">Masukkan Tanggal</span>
                    </div>
                </div>
                <div class="grid p-fluid">
                    <div class="col-4">
                        <span class="p-float-label mt-4">
                            <Dropdown dataEditKey="value" filter id="kategori_bayar_id" v-model.lazy="data.kategori_bayar_id" :options="kalenderBayar" optionLabel="text" optionValue="value" />
                            <label for="kategori_bayar_id">Kategori Bayar</label>
                        </span>
                    </div>
                    <div class="col-4">
                        <span class="p-float-label mt-4">
                            <Dropdown dataEditKey="value" filter id="nama_bank_pts" v-model.lazy="data.nama_bank_pts" :options="option_jenis" optionLabel="text" optionValue="value" />
                            <label for="nama_bank_pts">Bank / jalur</label>
                        </span>
                    </div>
                    <div class="col-4">
                        <span class="p-float-label mt-4">
                            <InputText id="no_va_pts" required="true" autofocus v-model.lazy="data.no_va_pts" />
                            <label for="no_va_pts">Nomor Virtual Account</label>
                        </span>
                    </div>
                </div>
                <div class="grid mt-4">
                    <div class="col-6"></div>
                    <div class="col-12 sm:col-6 text-right">
                        <div class="grid p-fluid">
                            <div class="col-4">
                                <Button class="p-button p-button-success" block label="Search" @click="get_tagihan()" />
                            </div>
                            <div class="col-4">
                                <Button class="p-button p-button-primary" label="Reset" @click="reset()" />
                            </div>
                            <div class="col-4">
                                <Button class="p-button p-button-warning" label="Excel" @click="downloadExcel()" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <h5>Rekap Tagihan Studi Mahasiswa</h5>
                <DataTable
                    :value="tagihan"
                    class="p-datatable-gridlines"
                    v-model:filters="filters1"
                    dataKey="id"
                    :rowHover="true"
                    filterDisplay="menu"
                    :loading="loading1"
                    :filters="filters1"
                    responsiveLayout="scroll"
                    :globalFilterFields="field"
                    :resizableColumns="true"
                >
                    <template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">
                            <div class="add"><Dropdown :options="perPages" id="Role" required="true" autofocus v-model.lazy="data.jumlah" @change="set_jumlah($event)" /> Per Halaman dari {{ total_tagihan }} data</div>
                            <div class="filter">
                                <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2" @click="clearFilter1()" />
                                <span class="p-input-icon-left mb-2">
                                    <i class="pi pi-search" />
                                    <InputText v-model="filters1.global.value" placeholder="Keyword Search" style="width: 100%" />
                                </span>
                            </div>
                        </div>
                    </template>
                    <template #empty> Tidak ada data. </template>
                    <template #loading> Loading data. mohon tunggu.... </template>
                    <Column field="no" header="No" style="width: 50px">
                        <template #body="{ data }">
                            {{ data.no }}
                        </template>
                    </Column>
                    <Column field="actions" header="Actions" bodyClass="text-center" style="min-width: 2rem">
                        <template #body="{ data }">
                            <div class="flex sm:flex-row">
                                <div class="mt-2" v-if="data.nama_bank_pts == 'Beasiswa'">
                                    <Button type="button" v-tooltip.top="'hapus tagihan mahasiswa'" icon="pi pi-trash" label="" class="p-button-danger ml-2" @click="deleteData(data)" />
                                </div>
                                <div class="mt-2">
                                    <Button type="button" v-tooltip.top="''" icon="" label="" class="p-button-secondary ml-2" style="background-color: transparent; border: none" />
                                </div>
                            </div>
                        </template>
                    </Column>
                    <Column field="NIM_PTS" header="NIM" style="min-width: 4rem" :sortable="true">
                        <template #body="{ data }">
                            {{ data.NIM_PTS }}
                        </template>
                    </Column>
                    <Column field="nama_mahasiswa" header="Nama Mahasiswa" style="min-width: 12rem" :sortable="true">
                        <template #body="{ data }">
                            {{ data.nama_mahasiswa }}
                        </template>
                    </Column>
                    <Column field="tahun_ak" header="Tahun Akademik" style="min-width: 4rem" :sortable="true">
                        <template #body="{ data }">
                            {{ data.tahun_ak }}
                        </template>
                    </Column>
                    <Column field="nama_kategori" header="Kategori" style="min-width: 4rem" :sortable="true">
                        <template #body="{ data }">
                            {{ data.nama_kategori }}
                        </template>
                    </Column>
                    <Column field="tanggal_penagihan" header="Tanggal Tagihan" style="min-width: 4rem" :sortable="true">
                        <template #body="{ data }">
                            {{ $moment(data.tanggal_penagihan).format('ll') }}
                        </template>
                    </Column>
                    <Column field="tanggal_dibayar" header="Tanggal Pelunasan" style="min-width: 4rem" :sortable="true">
                        <template #body="{ data }">
                            {{ $moment(data.tanggal_dibayar).format('ll') }}
                        </template>
                    </Column>
                    <Column field="nama_bank_pts" header="JALUR" style="min-width: 4rem" :sortable="true">
                        <template #body="{ data }">
                            {{ data.nama_bank_pts }}
                        </template>
                    </Column>
                    <Column field="ditagihkan_kepada" header="Oleh" style="min-width: 4rem" :sortable="true">
                        <template #body="{ data }">
                            {{ data.ditagihkan_kepada }}
                        </template>
                    </Column>
                    <Column field="total_tagihan" header="Total Tagihan" style="min-width: 4rem; text-align: right" :sortable="true">
                        <template #body="{ data }">
                            {{ $formats(data.total_tagihan).format() }}
                        </template>
                    </Column>
                    <Column field="status_pembayaran" header="Status" style="min-width: 6rem" :sortable="true">
                        <template #body="{ data }">
                            <Button v-if="data.status_pembayaran == 'LUNAS'" class="p-button-text p-button-success mr-2 mb-2"> {{ data.status_pembayaran }}</Button>
                            <Button v-else-if="data.status_pembayaran == 'DISPENSASI'" class="p-button-text p-button-warning mr-2 mb-2"> {{ data.status_pembayaran }}</Button>
                            <Button v-else-if="data.status_pembayaran == 'BELUM LUNAS'" class="p-button-text p-button-danger mr-2 mb-2"> {{ data.status_pembayaran }}</Button>
                        </template>
                    </Column>
                    <template #footer>
                        <Paginator :rows="data.jumlah" :totalRecords="total_tagihan" @page="onPage($event)"></Paginator>
                    </template>
                </DataTable>
            </div>
        </div>
    </div>
    <!-- </div>
    </div> -->
</template>
<script>
import { FilterMatchMode } from 'primevue/api';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
export default {
    setup() {
        return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
    },
    data() {
        return {
            tagihan: [],
            total_tagihan: 0,
            field: ['nama_kategori', 'nama_mahasiswa', 'nama_bank_pts', 'nama_kategori', 'tahun_ak', 'NIM_PTS'],
            loading1: true,
            prodi: [],
            tahun: [],
            kalenderBayar: [],
            data: {
                jumlah: 10,
                halaman: 1,
                NIM_PTS: null,
                nama_mahasiswa: null,
                total_tagihan: null,
                tanggal_dibayar: null,
                no_va_pts: null,
                tahun_kb: null,
                semester: null,
                kategori_bayar_id: null,
                prodi_id: null,
                jenis_beasiswa: null,
                nama_bank_pts: null,
                bayar_mulai: null,
                bayar_tutup: null,
                tahun_akademik_id: null,
            },
            checked: false,
            src1: null,
            file1: null,
            busy: false,
            dataEdit: {},
            filters1: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            option_jenis_beasiswa: [
                { value: 'beasiswa_poltekkes', text: 'Beasiswa Gakin' },
                { value: 'bidik_kamu', text: 'Bidikkamu' },
            ],
            option_jenis: [
                { value: 'BTN', text: 'BTN' },
                { value: 'BNI', text: 'BNI' },
                { value: 'BSI', text: 'BSI' },
                { value: 'Mandiri', text: 'Mandiri' },
                // { value: 'Beasiswa', text: 'Beasiswa' },
                // { value: 'Beasiswa GAKIN', text: 'Beasiswa GAKIN' },
                // { value: 'Beasiswa BIDIKKAMU', text: 'Beasiswa BIDIKKAMU' },
                // { value: 'Beasiswa Poltekkes', text: 'Beasiswa Poltekkes' },
                { value: 'Sipenmaru', text: 'Sipenmaru' },
                { value: 'Salah Bayar', text: 'Salah Bayar' },
                { value: 'Tunda Bayar', text: 'Tunda Bayar' },
                // { value: 'all', text: 'All Beasiswa' },
            ],
            perPage: 10,
            perPages: [10, 25, 50, 100],
        };
    },
    validations() {
        return {
            dataEdit: {
                NIM_PTS: {
                    required,
                },
                nama_mahasiswa: {
                    required,
                },
                total_tagihan: {
                    required,
                },
                tanggal_dibayar: {
                    required,
                },
                no_va_pts: {
                    required,
                },
                tahun_kb: {
                    required,
                },
                semester: {
                    required,
                },
                kategori_bayar_id: {
                    required,
                },
                prodi_id: {
                    required,
                },
                nama_bank_pts: {
                    required,
                },
                bayar_mulai: {
                    required,
                },
                bayar_tutup: {
                    required,
                },
                tahun_akademik_id: {
                    required,
                },
            },
        };
    },
    computed: {},
    mounted() {
        this.get_tagihan();
        this.get_data();
        this.v$.$reset();
    },
    methods: {
        clearFilter1() {
            this.filters1 = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        set_jumlah(x) {
            this.data.jumlah = x.value;
            this.data.halaman = 1;
            this.get_tagihan();
        },
        onPage(x) {
            console.log(x);
            this.data.halaman = x.page + 1;
            this.get_tagihan();
        },
        initFilters1() {
            this.filters1 = {
                nama_kategori: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        get_tagihan() {
            let vm = this;
            vm.loading1 = true;
            vm.$axiosbilling
                .post('pembayaranTagihanStudi/list', vm.data)
                .then((res) => {
                    // console.log(res.data);
                    if (res.data.status == 200) {
                        vm.tagihan = res.data.data;
                        for (let i = 0; i < vm.tagihan.length; i++) {
                            const el = vm.tagihan[i];
                            el.no = (vm.data.halaman - 1) * vm.data.jumlah + (i + 1);
                            if (el.total_tagihan == el.total_dibayar) {
                                el.status_pembayaran = 'LUNAS';
                            } else {
                                el.status_pembayaran = 'BELUM LUNAS';
                            }
                        }
                        vm.loading1 = false;
                        vm.total_tagihan = Number(res.data.count);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    vm.loading1 = false;
                });
        },
        async get_data() {
            let vm = this;
            let prodi = await vm.$axiosbilling('prodi/listHalaman');
            if (prodi.data.message == 'sukses') {
                vm.prodi = prodi.data.data.map((item) => {
                    return { ...item, value: item.id, text: item.nama_prodi };
                });
            }

            let tahun = await vm.$axiosbilling('tahunAkademik/listHalaman');
            if (tahun.data.message == 'sukses') {
                vm.tahun = tahun.data.data.map((item) => {
                    return { ...item, value: item.id, text: item.tahun_ak };
                });
            }

            let kalenderBayar = await vm.$axiosbilling('kategoriBayar/listByStatusKategoriBayar/4');
            // console.log(kalenderBayar, 'bayar');
            if (kalenderBayar.data.message == 'sukses') {
                vm.kalenderBayar = kalenderBayar.data.data.map((item) => {
                    return { ...item, value: item.id, text: item.nama_kategori };
                });
            }
        },
        editData(data) {
            let vm = this;
            vm.dataEdit = data;
            vm.src1 = vm.$ip2 + data.file_kategori_bayar;
            vm.modalEdit = true;
        },
        simpanEdit() {
            let vm = this;
            vm.busy = true;
            if (vm.isValidEdit) {
                vm.$axiosbilling
                    .post('kategoriBayar/update', vm.dataEdit)
                    .then((res) => {
                        // console.log(res.data.status);
                        if (res.data.status == 200) {
                            if (res.data.message == 'sukses') {
                                vm.modalEdit = false;
                                vm.$toast.add({ severity: 'success', summary: 'Konfirmasi', detail: 'Berhasil Mengupdate Data tagihan Biaya', life: 3000 });
                                this.get_tagihan();
                            } else {
                                vm.modalEdit = false;
                                vm.$toast.add({ severity: 'warn', summary: 'Konfirmasi', detail: res.data.message, life: 3000 });
                            }
                        } else {
                            vm.modalEdit = false;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        vm.modalEdit = false;
                        vm.$toast.add({ severity: 'error', summary: 'Konfirmasi', detail: 'Terjadi kesalahan pada server', life: 3000 });
                    });
            } else {
                vm.$toast.add({ severity: 'error', summary: 'Peringatan', detail: 'Masih terdapat isian data yang tidak valid', life: 3000 });
            }
        },
        approve(data, status) {
            let vm = this;
            vm.busy = true;
            data.status_kategori_bayar = status;
            vm.$axiosbilling
                .post('kategoriBayar/update', data)
                .then((res) => {
                    // console.log(res.data.status);
                    if (res.data.status == 200) {
                        if (res.data.message == 'sukses') {
                            vm.$confirm.close();
                            vm.$toast.add({ severity: 'success', summary: 'Konfirmasi', detail: 'Berhasil Mennyetujui tagihan Biaya', life: 3000 });
                            this.get_tagihan();
                        } else {
                            vm.$confirm.close();
                            vm.$toast.add({ severity: 'warn', summary: 'Konfirmasi', detail: res.data.message, life: 3000 });
                        }
                    } else {
                        vm.$confirm.close();
                    }
                })
                .catch((err) => {
                    console.log(err);
                    vm.modalApprove = false;
                    vm.$toast.add({ severity: 'error', summary: 'Konfirmasi', detail: 'Terjadi kesalahan pada server', life: 3000 });
                });
        },
        simpanDelete(data) {
            let vm = this;
            vm.$axiosbilling
                .post('pembayaranTagihanStudi/deleteBeasiswa', { id: data.penjualanTagihanStudiId })
                .then((res) => {
                    // console.log(res.data.status);
                    if (res.data.status == 200) {
                        if (res.data.message == 'sukses') {
                            vm.$toast.add({ severity: 'success', summary: 'Konfirmasi', detail: 'Berhasil Menghapus Data tagihan Biaya', life: 3000 });
                            this.get_tagihan();
                            this.$confirm.close();
                        } else {
                            vm.$toast.add({ severity: 'warn', summary: 'Konfirmasi', detail: res.data.message, life: 3000 });
                            this.$confirm.close();
                        }
                    } else {
                        vm.$toast.add({ severity: 'warn', summary: 'Konfirmasi', detail: 'data sudah ada', life: 3000 });
                        this.$confirm.close();
                    }
                })
                .catch((err) => {
                    console.log(err);
                    vm.$toast.add({ severity: 'error', summary: 'Konfirmasi', detail: 'Terjadi kesalahan pada server', life: 3000 });
                    this.$confirm.close();
                });
        },
        deleteData(data) {
            // console.log(data, 'ini datanya bossss');
            this.$confirm.require({
                message: 'Apakah anda yakin ingin menghapus pelunasan By Beasiswa ini?',
                header: 'Konfirmasi',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.simpanDelete(data);
                },
                reject: () => {
                    this.$confirm.close();
                },
            });
        },
        approveData(data, status) {
            // console.log(data, 'ini datanya bossss');
            this.$confirm.require({
                message: 'Apakah anda yakin ingin mennyetujui tagihan ini?',
                header: 'Konfirmasi',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.approve(data, status);
                },
                reject: () => {
                    this.$confirm.close();
                },
            });
        },
        checkValidasi(type, fieldName) {
            const field = this.v$[type][fieldName];
            let x = field.$errors[0].$validator;
            if (x == 'required') {
                return '* Data wajib diisi';
            } else if (x == 'email') {
                return '* Data harus berbentuk email';
            } else if (x == 'numeric') {
                return '* Data harus terdiri hanxa dari angka';
            } else if (x == 'minLength') {
                return `* Data minimal ${field.$errors[0].$params.min} digits`;
            } else if (x == 'maxLength') {
                return `* Data maksimal ${field.$errors[0].$params.max} digits`;
            } else if (x == 'alphaNum') {
                return `* Data tidak boleh mengandung spasi atau simbol`;
            } else if (x == 'official') {
                return `* Data tidak boleh mengandung spasi atau simbol`;
            }
        },
        reset() {
            this.checked = false;
            this.data = {
                jumlah: 10,
                halaman: 1,
                NIM_PTS: null,
                nama_mahasiswa: null,
                total_tagihan: null,
                tanggal_dibayar: null,
                no_va_pts: null,
                tahun_kb: null,
                semester: null,
                kategori_bayar_id: null,
                prodi_id: null,
                nama_bank_pts: null,
                bayar_mulai: null,
                bayar_tutup: null,
                tahun_akademik_id: null,
                jenis_beasiswa: null
            };
            this.get_tagihan()
        },
        async downloadExcel() {
            let vm = this;
            let x = `?download=true`;
            if (vm.data.NIM_PTS) {
                x += `&NIM_PTS=${vm.data.NIM_PTS}`;
            }
            if (vm.data.nama_mahasiswa) {
                x += `&nama_mahasiswa=${vm.data.nama_mahasiswa}`;
            }
            if (vm.data.tahun_akademik_id) {
                x += `&tahun_akademik_id=${vm.data.tahun_akademik_id}`;
            }
            if (vm.data.prodi_id) {
                x += `&prodi_id=${vm.data.prodi_id}`;
            }
            if (vm.data.total_tagihan) {
                x += `&total_tagihan=${vm.data.total_tagihan}'`;
            }
            if (vm.data.bayar_mulai) {
                x += `&bayar_mulai=${vm.$moment(vm.data.bayar_mulai).format('YYYY-MM-DD')}`;
            }
            if (vm.data.bayar_tutup) {
                x += `&bayar_tutup=${vm.$moment(vm.data.bayar_tutup).format('YYYY-MM-DD')}`;
            }
            if (vm.data.tanggal_dibayar) {
                x += `&tanggal_dibayar=${vm.data.tanggal_dibayar}`;
            }
            if (vm.data.kategori_bayar_id) {
                x += `&kategori_bayar_id=${vm.data.kategori_bayar_id}`;
            }
            if (vm.data.nama_bank_pts) {
                x += `&nama_bank_pts=${vm.data.nama_bank_pts}`;
            }
            if (vm.data.jenis_beasiswa) {
                x += `&jenis_beasiswa=${vm.data.jenis_beasiswa}`;
            }
            if (vm.data.no_va_pts) {
                x += `&no_va_pts=${vm.data.no_va_pts}`;
            }
            await window.open(vm.$ip2 + '/pembayaranTagihanStudi/excelPembayaranTagihan' + x, '_blank');
        },
    },
};
</script>
<style scoped lang="scss">
@import '@/assets/demo/styles/badges.scss';

::v-deep(.p-datatable-frozen-tbody) {
    font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
    font-weight: bold;
}

::v-deep(.p-progressbar) {
    height: 0.5rem;
    background-color: #d8dadc;

    .p-progressbar-value {
        background-color: #607d8b;
    }
}
</style>
